/* eslint-disable */

<template>
  <div>
    <vue-sortable :options="options" @drag:start="startedDrag" @drag:stop="stoppedDrag" @sortable:sort="sort">
      <div class="vx-col w-full">
        <vx-card class="mb-base">
          <h3 class="font-bold mb-4">Controls</h3>
          <vue-draggable-container id="wordBank" class="wordBank grid grid-cols-2">
            <h5 class="dragBlock font-semibold cursor-pointer h-max-content" v-for="block in dragBlocks">{{ block }}</h5>
          </vue-draggable-container>
        </vx-card>
      </div>
      <div class="vx-row" :class="{ 'cursor-drag': draggingCard === true }">
        <div class="vx-col lg:w-4/12 md:w-4/12 sm:w-5/5">
          <img
            src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/ccna/243684920157239864/image.png"
            class="w-full rounded-lg main-border"
          />
        </div>
        <div class="vx-col lg:w-8/12 md:w-8/12 sm:w-5/5">
          <div class="grid grid-cols-2 gap-5">
            <div class="flex flex-col justify-between rounded-lg p-4 bg-dark main-border">
              <h3 class="font-bold">Position A</h3>
              <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg w-full" id="input0" />
            </div>
            <div class="flex flex-col justify-between rounded-lg p-4 bg-dark main-border">
              <h3 class="font-bold">Position B</h3>
              <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg mt-4 w-full" id="input1" />
            </div>
            <div class="flex flex-col justify-between rounded-lg p-4 bg-dark main-border">
              <h3 class="font-bold">Position C</h3>
              <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg mt-4 w-full" id="input2" />
            </div>
            <div class="flex flex-col justify-between rounded-lg p-4 bg-dark main-border">
              <h3 class="font-bold">Position D</h3>
              <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg mt-4 w-full" id="input3" />
            </div>
            <div class="flex flex-col justify-between rounded-lg p-4 bg-dark main-border">
              <h3 class="font-bold">Position E</h3>
              <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg mt-4 w-full" id="input4" />
            </div>
            <div class="flex flex-col justify-between rounded-lg p-4 bg-dark main-border">
              <h3 class="font-bold">Position F</h3>
              <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg mt-4 w-full" id="input5" />
            </div>
          </div>
        </div>
      </div>
    </vue-sortable>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import Prism from 'vue-prism-component';
import shuffle from 'shuffle-array';

export default {
  data() {
    return {
      dataLocal: JSON.parse(JSON.stringify(this.data)),
      draggingCard: false,

      dragBlocks: [
        'ip address 172.16.1.1 255.255.255.0',
        'ip address 45.83.2.214 255.255.255.240',
        'ip nat inside',
        'ip nat inside source list 1 interface s0 overload',
        'ip nat inside source static tcp 172.16.1.2 80 45.83.2.214 80 extendable',
        'ip nat outside',
      ],

      options: {
        draggable: '.dragBlock',
        dropzone: '.dropzoneStandard',
      },
    };
  },
  methods: {
    startedDrag(x) {
      x.data.source.className += ' currently-dragging-standard';
      this.draggingCard = true;
    },
    stoppedDrag() {
      this.draggingCard = false;
    },
    sort(x) {
      const overContainer = x.data.dragEvent.data.overContainer;
      const sourceContainer = x.data.dragEvent.data.sourceContainer;
      if (overContainer && overContainer.id !== 'wordBank' && overContainer.children) {
        if (overContainer.children.length > 0 && overContainer.id !== sourceContainer.id) {
          x.cancel();
        }
      }
    },
    markSimulation() {
      let total_score = 0;

      if (document.getElementById('input0').textContent === 'ip address 172.16.1.1 255.255.255.0') {
        document.getElementById('input0').style.borderColor = '#28C76F';
        document.getElementById('input0').style.backgroundColor = '#28C76F';
        total_score++;
      } else {
        document.getElementById('input0').style.borderColor = '#fd4445';
        document.getElementById('input0').style.backgroundColor = '#fd4445';
      }

      if (document.getElementById('input1').textContent === 'ip nat inside') {
        document.getElementById('input1').style.borderColor = '#28C76F';
        document.getElementById('input1').style.backgroundColor = '#28C76F';
        total_score++;
      } else {
        document.getElementById('input1').style.borderColor = '#fd4445';
        document.getElementById('input1').style.backgroundColor = '#fd4445';
      }

      if (document.getElementById('input2').textContent === 'ip address 45.83.2.214 255.255.255.240') {
        document.getElementById('input2').style.borderColor = '#28C76F';
        document.getElementById('input2').style.backgroundColor = '#28C76F';
        total_score++;
      } else {
        document.getElementById('input2').style.borderColor = '#fd4445';
        document.getElementById('input2').style.backgroundColor = '#fd4445';
      }

      if (document.getElementById('input3').textContent === 'ip nat outside') {
        document.getElementById('input3').style.borderColor = '#28C76F';
        document.getElementById('input3').style.backgroundColor = '#28C76F';
        total_score++;
      } else {
        document.getElementById('input3').style.borderColor = '#fd4445';
        document.getElementById('input3').style.backgroundColor = '#fd4445';
      }

      if (document.getElementById('input4').textContent === 'ip nat inside source list 1 interface s0 overload') {
        document.getElementById('input4').style.borderColor = '#28C76F';
        document.getElementById('input4').style.backgroundColor = '#28C76F';
        total_score++;
      } else {
        document.getElementById('input4').style.borderColor = '#fd4445';
        document.getElementById('input4').style.backgroundColor = '#fd4445';
      }

      if (document.getElementById('input5').textContent === 'ip nat inside source static tcp 172.16.1.2 80 45.83.2.214 80 extendable') {
        document.getElementById('input5').style.borderColor = '#28C76F';
        document.getElementById('input5').style.backgroundColor = '#28C76F';
        total_score++;
      } else {
        document.getElementById('input5').style.borderColor = '#fd4445';
        document.getElementById('input5').style.backgroundColor = '#fd4445';
      }

      if (total_score === this.dataLocal.simulation.totalScore) {
        return this.$emit('simulation_full_correct', total_score);
      }

      this.$emit('simulation_getting_there', total_score);
    },
  },
  created() {
    this.dragBlocks = shuffle(this.dragBlocks);
  },
  components: {
    draggable,
    Prism,
    shuffle,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
#wordBank {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 0.6rem;
  min-height: 30px;
}
</style>
